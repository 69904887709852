import React from 'react'
import { Link } from 'gatsby'
import { ArrowRight } from '@dasa-health/alma-icons'
import ImgbannerInstitutional from './imgbannerInstitutional'
import ImgGenetic from './imgGenetic'
import Img from 'gatsby-image'
import { CircleButton } from '../shared/CircleButton/styled'

import * as S from './styled'

const BannerInstitutional = ({ data }) => {
  const wppData = data.wordpressWpGatsbyPage.acf

  return (
    <S.BannerContainer>
      <S.ImageContainer>
        {wppData.placeholder_img
          ?
            <Img 
              alt={wppData.placeholder_img.alt_text}
              fluid={wppData.placeholder_img.localFile.childImageSharp.fluid} />
          :
            <ImgbannerInstitutional />
        }
      </S.ImageContainer>

      <S.GeneticImageContainer>
        <S.GeneticImage>
          {wppData.floating_image_home
            ? 
              <Img 
                alt={wppData.floating_image_home.alt_text}
                fluid={wppData.floating_image_home.localFile.childImageSharp.fluid} 
              />
            :
              <ImgGenetic />
          }
        </S.GeneticImage>
      </S.GeneticImageContainer>

      <S.InstitutionalContainer>
        <S.InstitutionalContent>
          <S.TextOverline>
            {wppData.placeholder_overline
              ?
                wppData.placeholder_overline
              :
                "Sobre Nós"
            }
          </S.TextOverline>

          <S.FirstInstitutionalText>
            {wppData.placeholder_title
              ?
                wppData.placeholder_title
              :
                "Excelência em genômica"
            }
          </S.FirstInstitutionalText>

          <S.SecondInstitutionalText>
            {wppData.placeholder_description
              ?
                wppData.placeholder_description
              :
                "Combinamos excelência científica e tecnológica em diagnósticos precisos, para viabilizar e compartilhar um novo modelo de cuidado: preventivo, preditivo, personalizado e por toda vida."
            }
          </S.SecondInstitutionalText>

          <Link
            to={
              wppData.placeholder_link 
                ?
                  wppData.placeholder_link 
                :
                  "/sobre/"
            }
            aria-label="Link para página sobre nós"
          >
            <CircleButton>
              <ArrowRight />
            </CircleButton>
          </Link>
        </S.InstitutionalContent>

      </S.InstitutionalContainer>

    </S.BannerContainer>
  )
}

export default BannerInstitutional