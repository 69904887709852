import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/seo'
import BannerInstitutional from 'components/BannerInstitutional'
import ClinicalStaffHomeWidget from 'components/clinical-staff/HomeWidget'
import FeaturedArticle from 'components/FeaturedArticle'

const IndexPage = ({ data, location, pageContext }) => {
  const wordpressData = data.wordpressWpGatsbyPage.acf

  return (
    <Layout location={location} pageContext={pageContext}>
      <SEO 
        title={wordpressData.title}
        description={wordpressData.description}
        canonical={wordpressData.canonical}
        pathName={location.pathname}
        home
      />
      <BannerInstitutional data={data} />
      <ClinicalStaffHomeWidget data={data} />
      <FeaturedArticle data={data} />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query($slug: String!) {
    wordpressWpGatsbyPage(slug: { eq: $slug }) {
      acf {
        canonical
        title
        subtitle
        placeholder_background          
        placeholder_overline
        placeholder_title
        placeholder_description
        placeholder_link
        placeholder_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        floating_image_home {
          alt_text
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        clinical_staff_title
        clinical_staff_description
        article_overline
        article_title
        article_text
        link_article_button
        show_article_blog
        img_article_blog {
            alt_text
            localFile {
                childImageSharp {
                    fluid(quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
      }
    }
  }
`