import React from 'react'
import { Link } from 'gatsby'
import { ArrowRight } from '@dasa-health/alma-icons'
import Img from 'gatsby-image'

import * as S from './styled'

const FeaturedArticle = ({ data }) => {
    const homeData = data.wordpressWpGatsbyPage.acf

    return (
        homeData && homeData.show_article_blog === true
        ?
            <>
                <S.FeatureArticleBackground>
                    <S.FeaturedArticleContainer>
                        <S.FeaturedArticleContent>

                            <S.ArticleContent>
                                <S.OverlineText to={homeData.link_article_button}>
                                    {homeData.article_overline}
                                </S.OverlineText>

                                <S.ArticleTitle>
                                    {homeData.article_title}
                                </S.ArticleTitle>

                                <S.ArticleText>
                                    {homeData.article_text}
                                </S.ArticleText>

                                <Link 
                                    to={homeData.link_article_button} 
                                    aria-label="Link para a postagem no blog"
                                >
                                    <S.DotWhite>
                                        <ArrowRight />
                                    </S.DotWhite>
                                </Link>
                            </S.ArticleContent>

                        </S.FeaturedArticleContent>

                        <S.ArticleImgContainer>
                            <Img 
                                fluid={homeData.img_article_blog.localFile.childImageSharp.fluid}
                                alt={homeData.img_article_blog.alt_text}
                            />
                        </S.ArticleImgContainer>
                    </S.FeaturedArticleContainer>
                </S.FeatureArticleBackground>
                <S.BurntYellowContainer />
            </>
        :
            <S.BurntYellowContainer />
    )
}

export default FeaturedArticle
