import React, { useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { ArrowRight, ArrowLeft, ChevronRight } from '@dasa-health/alma-icons'
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';
import arrayPagination from '../../../utils/arrayPaginate';
import formatDoctorNameTwoLines from '../../../utils/formatDoctorNameTwoLines';
import DoctorLink from '../../../utils/getDoctorLink';
import Img from 'gatsby-image'
import getSlugByCountry from '../../../utils/getSlugByCountry';
import * as S from './styled'

import 'swiper/swiper.min.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'

SwiperCore.use([Autoplay, Pagination, Navigation]);

const ClinicalStaffHomeWidget = ({ data }) => {
  const clinicalStaffPageSlug = getSlugByCountry('corpoClinico')
  const dataQuery = useStaticQuery(
    graphql`
      query {
        allWordpressWpClinicalStaffPage(sort: { fields: acf___name, order: ASC }) {
          nodes {
            wordpress_id
            slug
            acf {
              council_number
              title_gender
              name
              description
              specialization_area
              photo_medic {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  function sortDoctors(doctors) {
    return doctors.sort(function(){return 0.5 - Math.random()})
  }

  const [paginatedDoctors, setPaginatedDoctors] = useState([]);

  const clinicalStaffData = dataQuery.allWordpressWpClinicalStaffPage.nodes
  const widgetData = data.wordpressWpGatsbyPage.acf
  
  useEffect(() => {
    function paginateDoctors(doctors) {
      var doctorsSort = sortDoctors(doctors);
      var array = [];
  
      doctorsSort = alphabeticalOrder(arrayPagination(doctorsSort, 6)) // page at 6 for viewing and sorting
  
      for(var i=0;i<doctorsSort.length; i++) { // create array with all elements sorted
        for(var i2=0;i2<doctorsSort[i].length; i2++){
          array.push(doctorsSort[i][i2])
        }
      }
  
      doctorsSort = arrayPagination(array, 3) // page in 3 to build component
  
      setPaginatedDoctors(doctorsSort)
    }
  
    function order(array) {
      return array.sort(function(a,b) {
        if(a.acf.name < b.acf.name) {
          return -1
        } else {
          return true
        }
      })
    }
    
    function alphabeticalOrder(doctors) {
      for(var i=0; i<doctors.length; i++) {
        doctors[i] = order(doctors[i]);
      }
  
      return doctors
    }
    
    paginateDoctors(clinicalStaffData)
    
  }, [clinicalStaffData])

  function renderItem(doctor, key) {
    return (
      <S.ClinicalStaffItem key={`doctor_${key}`}>
        <S.ClinicalStaffImgDiv>
          {doctor.acf.photo_medic.localFile && (
            <Img fluid={doctor.acf.photo_medic.localFile.childImageSharp.fluid} />
          )}
        </S.ClinicalStaffImgDiv>

        <S.ItemColumnLeft>
          <S.ClinicalStaffName>
            <Link 
              to={DoctorLink({ title: doctor.acf.title_gender, name: doctor.slug })}
              aria-label={`${doctor.acf.title_gender} ${doctor.acf.name}`}
            >
              <div dangerouslySetInnerHTML={{ __html: formatDoctorNameTwoLines(doctor.acf.title_gender, doctor.acf.name) }} />
            </Link>
          </S.ClinicalStaffName>

          <S.ClinicalStaffSpecialization>
            {doctor.acf.specialization_area}
          </S.ClinicalStaffSpecialization>
        </S.ItemColumnLeft>

        <S.ItemColumnRight>
          <S.Link>
            <Link 
              to={DoctorLink({ title: doctor.acf.title_gender, name: doctor.slug })}
              aria-label={`${doctor.acf.title_gender} ${doctor.acf.name}`}
            >
              <ChevronRight />
            </Link>
          </S.Link>
        </S.ItemColumnRight>
      </S.ClinicalStaffItem>
    )
  }

  const navigationPrevRef2 = React.useRef(null)
  const navigationNextRef2 = React.useRef(null)

  return (
    <S.ClinicalStaffBackground>
      <S.ClinicalStaffContainer>
        <S.ClinicalStaffContent>
          <S.ClinicalStaffColumnInfo>
            <S.ClinicalStaffText>

              <S.ClinicalStaffTitle>
                {widgetData.clinical_staff_title}
              </S.ClinicalStaffTitle>

              <S.ClinicalStaffDescription>
                {widgetData.clinical_staff_description}
              </S.ClinicalStaffDescription>

              <Link to={`/${clinicalStaffPageSlug}/`} aria-label="Link para a página do corpo clínico">
                <S.CircleButtonBlue className='hiddenMobile'>
                  <ArrowRight />
                </S.CircleButtonBlue>
              </Link>
            </S.ClinicalStaffText>
          </S.ClinicalStaffColumnInfo>

          <S.ClinicalStaffCarousel>

            <Swiper
              className='swiper-two'
              slidesPerView={"auto"}
              grabCursor={true}
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: {
                  slidesPerGroup: 1,
                },
                1200: {
                  slidesPerGroup: 2,
                },
              }}
              autoplay={{
                delay: 10000,
                disableOnInteraction: false,
              }}
              navigation={{
                prevEl: navigationPrevRef2.current,
                nextEl: navigationNextRef2.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = navigationPrevRef2.current;
                swiper.params.navigation.nextEl = navigationNextRef2.current;
              }}
              pagination={{
                clickable: true
              }}
            >

              {paginatedDoctors.length > 0 && (
                paginatedDoctors.map((doctors, key) => (
                  <SwiperSlide key={`doctor_${Math.random()}`}>
                    {doctors.map(doctor => (
                      renderItem(doctor, Math.random())
                    ))}
                  </SwiperSlide>
                ))
              )}

              <SwiperSlide className="swiper-filler swiper-two"></SwiperSlide>

              <S.BtnsContainer>
                <S.BtnSwiperPrev ref={navigationPrevRef2} aria-label="Voltar para a página interior">
                  <ArrowLeft />
                </S.BtnSwiperPrev>

                <S.BtnSwiperNext ref={navigationNextRef2} aria-label="Ir para a próxima página">
                  <ArrowRight />
                </S.BtnSwiperNext>
              </S.BtnsContainer>
            </Swiper>
            
            <S.ButtonMobile>
              <Link to={`/${clinicalStaffPageSlug}/`} aria-label="Link para a página do corpo clínico">
                <S.CircleButtonBlue className='hiddenDesktop'>
                  <ArrowRight />
                </S.CircleButtonBlue>
              </Link>
            </S.ButtonMobile>
          </S.ClinicalStaffCarousel>
        </S.ClinicalStaffContent>
      </S.ClinicalStaffContainer>
    </S.ClinicalStaffBackground >
  )
}

export default ClinicalStaffHomeWidget
