import styled from 'styled-components'
import media from 'styled-media-query'
import { Link } from 'gatsby'
import { alvTokens } from '@dasa-health/alma-tokens'

export const FeatureArticleBackground = styled.div`
    width: 100%;
    background-color: var(--pink);
`

export const FeaturedArticleContainer = styled.div`
    display: flex;
    max-width: 1366px;
    margin: 0 auto;

    ${media.between('medium', 'large')`
    `}

    ${media.lessThan('medium')`
        flex-direction: column-reverse;
    `}
`

export const FeaturedArticleContent = styled.div`
    min-width: 695px;

    ${media.between('medium', 'large')`
        min-width: 50%;
    `}

    ${media.lessThan('medium')`
        min-width: 100%;
        margin-bottom: 4rem;
    `}
`

export const ArticleContent = styled.div`
    display: flex;
    max-width: 423px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    align-content: center;
    margin: 0 auto;
    height: 100%;

    ${media.between('medium', 'large')`
        max-width: 366px;
    `}

    ${media.lessThan('medium')`
        max-width: calc(100vw - 3.2rem);
        align-items: flex-start;
    `}
`

export const ArticleImgContainer = styled.div`
    max-width: 671px;
    max-height: 697px;

    .gatsby-image-wrapper {
        width: 671px;
        height: 697px;
    }

    ${media.between('medium', 'large')`
        width: 100%;    
        max-width: 100vw;
        max-height: 100vh;
        min-height: 458px;

        .gatsby-image-wrapper {
            width: 100%;
            height: 100%;
        }
    `}

    ${media.lessThan('medium')`
        max-width: 100vw;
        max-height: 100vh;
        margin-bottom: 4rem;

        svg,
        rect {
            width: 100%;
            height: 100%;
        }
    `}
`

export const OverlineText = styled(Link)`
    font-weight: 700;
    font-size: 12px
    line-height: 133%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    text-decoration: none;
    color: ${alvTokens.ColorPaletteWhite};
`

export const ArticleTitle = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
    letter-spacing: -1px;
    color: ${alvTokens.ColorPaletteWhite};
    margin: 2.4rem 0;
    text-align: right;
    
    ${media.between('medium', 'large')`
        font-size: 24px;
    `}
    
    ${media.lessThan('medium')`
        font-size: 24px;
        line-height: 133%;
        text-align: left;
    `}
`

export const ArticleText = styled.p`
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: ${alvTokens.ColorPaletteWhite};
    margin: 0 0 2.4rem 0;
    text-align: right;

    ${media.between('medium', 'large')`
    `}
    
    ${media.lessThan('medium')`
        text-align: left;
    `}
`

export const BurntYellowContainer = styled.div`
    width: 100%;
    height: 8rem;
    background-color: #CEB189;
    
    ${media.lessThan('medium')`
        height: 4rem;
    `}
`

export const DotWhite = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    background-color: var(--white);
    border-radius: 50%;

    svg {
        font-size: 32px;
        color: var(--pink);
    }
`;