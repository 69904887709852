import styled from 'styled-components'
import media from 'styled-media-query'
import { alvTokens } from '@dasa-health/alma-tokens'

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: flex-end;
  width: 100vw;
  height: 52.7rem;
  max-width: 1366px;
  margin: 8rem auto;
  position: relative;
  background: var(--brand-dasa);

  ${media.between('medium', 'large')`
    margin: 3.6rem auto 8.8rem;
    height: 36rem;
  `}

  ${media.lessThan('medium')`
    flex-direction: column; 
    justify-content: flex-start;
    margin: 0 auto 2.4rem;
    height: auto;
  `}
`
export const ImageContainer = styled.div`
  position: absolute;
  left: 0;
  width: 49.122%;
  height: 69.7rem;
  z-index: 10;
  
  ${media.between('medium', 'large')`
    top: 1rem;
    width: 41.122%;
  `}

  ${media.lessThan('medium')`
    position: inherit;
    width: 100%;
    height: auto;
  `}

  .gatsby-image-wrapper {
    height: 69.7rem;

    ${media.lessThan('medium')`
      min-height: 33.2rem;
      height: auto;
    `}

    ${media.between('medium', 'large')`
      height: 38rem;
    `}
  }
`

export const InstitutionalContainer = styled.div`
  width: 1094px;
  margin: 0 auto;
  position: relative;

  ${media.lessThan('medium')`
    max-width: calc(100vw - 3.2rem);
  `}
`

export const InstitutionalContent = styled.div`
  width: 423px;
  position: absolute;
  top: 18.8rem;
  right: 0;

  ${media.between('medium', 'large')`
    top: 2.4rem;
  `}

  ${media.lessThan('medium')`
    position: inherit;
    top: 0;
    width: 100%;
    margin-top: 2.4rem;
  `}
`

export const GeneticImageContainer = styled.div`
  position: absolute;
  top: -16.3rem;
  right: 8.9rem;

  ${media.between('medium', 'large')`
    top: -10rem;
    right: 2.9rem;
  `}

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const GeneticImage = styled.div`
  width: 18.5rem;
  
  ${media.between('medium', 'large')`
    width: 9.5rem;
  `}
`

export const TextOverline = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: 1.5px;
  color: ${alvTokens.ColorPaletteWhite};
  text-transform: uppercase;
  margin: 0;

  ${media.lessThan('medium')`
    display: none;
  `}
`

export const FirstInstitutionalText = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  letter-spacing: -1px;
  color: ${alvTokens.ColorPaletteWhite};
  margin: 2.4rem 0;
  
  ${media.lessThan('medium')`
    font-size: 24px;
    line-height: 133%;
    max-width: 250px;
    margin: 0 0 2.4rem;
  `}
`

export const SecondInstitutionalText = styled.p`
  font-weight: 400;
  font-size: 16px;
  color: ${alvTokens.ColorNeutral20};
  line-height: 150%;
  margin: 0 0 2.4rem;
`